<template>
  <v-container
    fluid
    tag="section"
  >
  <v-row v-if="$route.params.menu != 'crear'">
    <v-col cols="12" sm="8" md="8" lg="8" class="pa-0 text-right">
      <v-spacer />
      <template>
        <span class="px-2 font-weight-bold">Traducir:</span>
        <v-btn icon color="sidebar" @click="menuLang('en')">
          <div style="font-size:18px"><flag iso="us" /></div>
        </v-btn>
        <v-btn icon color="sidebar" @click="menuLang('fr')">
          <div style="font-size:18px"><flag iso="fr" /></div>
        </v-btn>
        <v-btn icon color="sidebar" @click="menuLang('zh')">
          <div style="font-size:18px"><flag iso="cn" /></div>
        </v-btn>
      </template>
    </v-col>
  </v-row>
  <validation-observer ref="menu_form" v-slot="">
  <v-row>
    <v-col cols="12" sm="8" md="8" lg="8" class="pb-0">
      <div  class="pb-0">
        <validation-provider name="Título" vid="name" rules="required" v-slot="{ errors }">
          <v-text-field v-model="menu.name" dense rounded single-line prepend-inner-icon="mdi-pasta" label="Título del Menú" outlined :error-messages="errors[0]"></v-text-field>
        </validation-provider>
      </div>
      <div class="pt-0 editor">
        <validation-provider name="Descripcion" vid="description" v-slot="{ errors }">
        <quill-editor
          v-model="menu.description"
          :class="{'error-editor': errors[0]}"
          :options="editorOption"/>
        <div class="v-messages theme--light error--text"> <span class="v-messages__message" v-text="errors[0]"></span></div>
        </validation-provider>
      </div>
      <v-card flat class="mt-2">
        <v-card-title class="primary--text title-card">Imagen del menu</v-card-title>
        <v-card-text class="d-flex align-center justify-center pa-0" style="height:15rem"  v-if="cargaInicialImagen" :class="{'imagezone': !cargaInicialImagen}">
          <template>
            <div class="v-responsive v-image grey lighten-2" :class="{'d-none': !cargaInicialImagen}" style="height:15rem; width:100%">
              <div id="imagePreviewmenu" class="v-image__image v-image__image--cover">
              </div>
                <v-overlay absolute :opacity="0.1" :value="true" color="light-blue lighten-5">
                <v-btn icon x-large color="orange lighten-2" @click="deleteImage">
                  <v-icon size="40">mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-overlay>
            </div>
          </template>
        </v-card-text>
        <v-card-text class="d-flex align-center justify-center pa-0" style="height:15rem"  v-else :class="{'imagezone': cargaInicialImagen}">
          <template>
            <div class="v-responsive v-image grey lighten-2" :class="{'d-none': cargaInicialImagen}" style="height:15rem; width:100%; min-width:700px; min-height: 300px">
              <croppa
                style="margin:auto"
                :width="700"
                :height="300"
                :quality="1"
                placeholder="Elige la imagen del menu"
                :placeholder-font-size="18"
                v-model="imagenMenu"
                initial-position="center"
                :show-remove-button="true"
                remove-button-color="black"
              >
              </croppa>
            </div>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
      <v-navigation-drawer
        v-model="side"
        color="sidebar_right"
        permanent
        right
        style="z-index: 0 !important"
        :absolute="!$vuetify.breakpoint.xs"
        :width="$vuetify.breakpoint.xs ? '100%' : '30%'"
        disable-resize-watcher
        disable-route-watcher
        stateless
      >
        <v-row>
          <v-col cols="12" class="py-0">
            <v-card flat class="mt-1 px-4 transparent">
              <v-card-title class="primary--text title-card">Publicar</v-card-title>
              <v-card-text class="outlined-card">
                <v-row>
                  <v-col cols="12" class="px-1 py-2 text-right">
                    <!-- <v-btn small class="py-2 font-weight-bold left" rounded outlined color="primary" @click="validarDashe(0)">
                      Guardar borrador
                    </v-btn> -->
                    <v-switch
                      v-model="menu.visibility"
                      :label="visibilidadNombre"
                      @change="cambiarVisibilidad($event)"
                      color="success"
                      ></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="px-1 py-2 text-right">
                    <!-- <v-btn small class="py-2 font-weight-bold left" rounded outlined color="primary" @click="validarDashe(0)">
                      Guardar borrador
                    </v-btn> -->
                    <v-switch
                      v-model="menu.show_price"
                      :label="showPriceNombre"
                      @change="cambiarShowPrice($event)"
                      color="success"
                      ></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pa-0">
                    <v-list dense>
                      <v-list-item style="min-height: 20px !important;" v-if="$route.params.menu != 'crear'">
                        <v-list-item-content class="py-0">
                          <v-list-item-title class="primary--text text--lighten-1 d-flex flex-row align-center" style="font-size:1rem !important">
                            <span class="pr-3">Visibilidad: </span>
                            <template>
                              <span class="font-weight-bold">{{ menu.visibility | visible }}</span>
                            </template>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item style="min-height: 20px !important;">
                        <v-list-item-content class="py-0">
                          <v-list-item-title class="primary--text text--lighten-1" style="font-size:1rem !important">
                            <span class="pr-3" v-text="$route.params.option != 'crear' ? 'Publicado: ' : 'Publicar: '" ></span>
                            <template v-if="$route.params.option != 'crear'">
                              <strong>{{ menu.publication_date | smartDate }}</strong>
                            </template>
                            <b v-else>Inmediatamente</b>
                        </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card-text>

            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <!-- <v-col cols="12" class="py-0 pl-0" v-if="menu.show_price">
            <v-card flat class="mt-0 px-4 transparent">
              <v-card-title class="primary--text title-card py-0">Precio</v-card-title>
              <v-card-text class="py-0">
                <v-row>
                  <v-col cols="12" class="pb-0 pl-1">

                      <v-text-field dense rounded single-line filled class="filled-white pb-0" outlined v-model="menu.price"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col> -->
          <v-col cols="12" class="py-0" v-if="menu.show_price">
            <v-card flat class="my-0 px-4 transparent">

              <v-card-title class="primary--text title-card py-0">Precios</v-card-title>
              <v-card-text class="outlined-card scrollable" style="height:12rem">
                <v-row v-for="(prices,index) in menu.price" :key="index" v-show="menu.price[0].name != ''">
                  <v-col class="col-12">
                    {{prices.name}}
                  </v-col>
                  <v-col v-for="(price,i) in prices.prices" :key="i">
                    {{ formatNumber(price.amount,coinCountry[price.coin]) }}
                    <!-- {{price.coin}} : {{price.amount}}{{ symbolCoin(price.coin) }} -->
                  </v-col>
                  <br>
                </v-row>
              </v-card-text>

              <v-card-actions class="d-flex justify-center">
                <v-btn dense class="py-2 font-weight-bold" rounded outlined color="primary" @click="modal_prices = true" :small="$vuetify.breakpoint.smAndDown">Editar precios</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-0" >
            <v-card flat class="my-0 px-4 transparent">
              <v-card-title class="primary--text title-card py-0">
                Platos
              </v-card-title>
              <v-card-text class="outlined-card scrollable" style="height:18rem">
                <v-row>
                  <v-col cols="12">
                    <template v-for="(dishe, i) in dishes">
                      <v-checkbox
                        small
                        color="info"
                        :value="dishe.id"
                        v-model="menu.dishes"
                        class="py-0 mt-1"
                        hide-details
                        :key="i"
                      >
                        <template v-slot:label><span class="primary--text" v-text="dishe.name.es"></span></template>
                      </v-checkbox>
                    </template>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
<!--         <v-row>
          <v-col cols="12" class="py-0" >
            <v-card flat class="my-4 px-4 transparent">
              <v-card-title class="primary--text title-card py-0">Imagén del Menú</v-card-title>
              <v-card-text class="outlined-card scrollable">
                <v-row>
                  <v-col cols="12" class="pt-4">
                    <input type="file"
                      class="d-none"
                      name="filemenu"
                      id="filemenu"
                      ref="filemenu"
                      data-allow-reorder="true"
                      data-max-file-size="1MB"
                      data-max-files="1"
                      accept="image/*"
                      @change="renderImage"
                      >
                    <a href="//" class="sidebar--text" v-if="menu.image.length === 0" @click.prevent="$refs.filemenu.click()">Establecer imagen</a>

                    <v-avatar size="42">
                      <img :src="storage + menu.image" :class="{'d-none': menu.image.length === 0}"/>
                    </v-avatar>
                    <v-btn text color="info" class="mx-3" :class="{'d-none': menu.image.length === 0}"  @click="deleteImage"> <v-icon left>mdi-trash-can-outline</v-icon> Eliminar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col>
            <v-card-actions class="d-flex justify-center pr-0 pb-0">
                <v-btn class="mr-0 font-weight-bold" color="primary" large @click="validarDashe(1)" v-text="menu.visibility == 0 ? 'Publicar' : $route.params.menu != 'crear' ? 'Actualizar' : 'Publicar'">
                </v-btn>
              </v-card-actions>
          </v-col>

        </v-row>
      </v-navigation-drawer>
    </validation-observer>

    <!-- *******MODAL DE TRADUCCIONES****** -->
    <base-material-modal v-model="modal_menu" icon="mdi-flag" :loadcard="saving_menu" :title="`Añadir Traducción (${lang})`" width="900">
      <template v-slot:content >
        <validation-observer ref="menu_lang" v-slot="">
          <v-row style="height: 100;">
            <v-col cols="12" class="pb-0" >
              <validation-provider name="Título" vid="name" rules="required" v-slot="{ errors }">
                <v-text-field v-model="traslate.name" dense rounded single-line prepend-inner-icon="mdi-playlist-edit" label="Título del Menú" outlined :error-messages="errors[0]"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" class="pb-0" >
              <validation-provider name="Descripcion" vid="description" rules="required|max:180" v-slot="{ errors }">
                <quill-editor
                v-model="traslate.description"
                :class="{'error-editor': errors[0]}"
                :options="editorOption"/>
                <div class="v-messages theme--light error--text"> <span class="v-messages__message" v-text="errors[0]"></span></div>
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
      </template>
      <template v-slot:actions>
         <v-btn
            depressed
            small
            @click="modal_menu = false"
            :disabled="saving_menu"
          >
            Descartar
          </v-btn>
          <v-btn color="primary" tile small :loading="saving_menu" @click="validateLang">
            Guardar traducción
          </v-btn>
      </template>
    </base-material-modal>
    <!-- *******MODAL DE PRECIOS****** -->
    <base-material-modal v-model="modal_prices" icon="mdi-animation-outline" :loadcard="saving_price" title="Editar Precios">
      <template v-slot:content>
        <validation-observer ref="prices_menu" v-slot="">
          <v-row v-for="(price,index) in menu.price" :key="index">
             <v-col cols="12" class="pb-0">
              <span>Variación</span>
            </v-col>
            <v-col cols="12" class="pb-0" >
              <validation-provider name="Nombre" vid="name" rules="required" v-slot="{ errors }">
                <v-text-field outlined dense rounded prepend-inner-icon="mdi-playlist-edit" v-model="price.name" :error-messages="errors[0]" hint="El nombre de la variación del producto" persistent-hint :disabled="saving_catg"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" class="pb-0" >
              <div class="d-block" v-for="(p,i) in price.prices" :key="i">
                <validation-provider name="Montos" vid="amount" rules="required" v-slot="{errors  }">
                    <v-text-field
                      v-model="p.amount"
                      :counter="10"
                      :label="labelAmount(p.coin)"
                      required
                      :error-messages="errors[0]"
                       :rules="[rules.required,rules.money]"
                    ></v-text-field>
                    </validation-provider>
              </div>
            </v-col>
            <v-col>
              <v-btn small @click="removePrice(index)" style="margin-top: 20px;"><v-icon>mdi-cancel</v-icon></v-btn>
            </v-col>
          </v-row>
        </validation-observer>
      </template>
      <template v-slot:actions>

         <v-btn
            depressed
            small
            @click="restorePrices"
            :disabled="saving_catg"
            rounded
            style="padding-right: 20px;padding-left: 20px;"
          >
            Descartar
          </v-btn>
          <v-btn class="primary" depressed rounded small :loading="saving_catg" @click="addPrice">Agregar variación</v-btn>
          <v-btn color="primary" depressed rounded small :loading="saving_catg" @click="validatePric" style="padding-right: 20px;padding-left: 20px;">
            Añadir
          </v-btn>
      </template>

    </base-material-modal>

    <v-overlay absolute :value="loading.active" :opacity="loading.opacity" :z-index="10" color="white" class="text-center">
      <v-img
        contain
        max-width="120"
        :src="require('@/assets/Todo-carta-logo.png')"
      ></v-img>
      <v-progress-linear
          color="blue-grey lighten-3"
          indeterminate
          rounded
          height="4"
        ></v-progress-linear>
      <h5 class="blue-grey--text mt-4">{{ loading.text }}</h5>
    </v-overlay>
  </v-container>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import coinSymbol from "../../../mixins/coinSymbol"
import coinCountry from "../../../mixins/coinCountry"

import {quillEditor} from 'vue-quill-editor'
  export default {
    name: 'CreateMenu',
    components:{
      quillEditor
    },
    data () {
      return {
        imagenMenu :{},
        cargaInicialImagen:true,
        visibilidad:'',
        menu:{
          description: '',
          name: '',
          visibility: '',
          lang: 'es',
          image: '',
          restaurant_id: '',
          dishes: [],
          id: '',
          image_delete: false,
          price: '',
          publication_date: '',
          show_price:false
        },
        menu_lang:{
          description: '',
          name: '',
        },
        traslate:{
          description: '',
          name: '',
          lang: '',
        },
        loading:{
          text: '',
          opacity: '',
          active: false
        },
        dishes: [],
        description:'',
        side: true,
        saving_catg: false,
        modal_menu: false,
        saving_price:false,
        saving_menu: false,
        editorOption: {
          placeholder: "Descripcion",
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'script': 'sub' }, { 'script': 'super' }],
              // [{ 'size': ['small', false, 'large', 'huge'] }],
              // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              // [{ 'font': [] }],
              [{ 'color': [] }, { 'background': [] }],
              ['clean'],
            ],
          }
        },
        redirect: '',
        storage: '',
        modal_prices:false,
        coins:[],
        rules: {
          required: value => !!value || 'Required.',
          money: value => {
            const pattern = new RegExp(/^\d+(,[0-9]{1,2})/)
            const pattern2 =  new RegExp(/^\d+$/)
            return (pattern.test(value) || pattern2.test(value)) || 'Formato incorrecto 20,00'
          }
        }
      }
    },
    created(){
      this.init();
      this.storage = process.env.VUE_APP_IMG_URL_API + 'images/menu/';
    },
    beforeRouteUpdate (to, from, next) {
      let Exp = /^([0-9])*$/;
        if(Exp.test(to.params.menu) || to.params.menu === 'crear'){
          this.resetmenu();
          if(this.$store.getters['auth/isAdmin']){
            this.menu.restaurant_id = to.params ? to.params.id : '';
            this.redirect = `/tc-admin/restaurante/${this.menu.restaurant_id}/administrar/cartas`;
          }
          else{
            this.menu.restaurant_id =  this.$store.getters['auth/restaurant'];
            this.redirect = '/tc-admin/cartas';
          }
          this.getForm();
          next();
        }
        else{
          next('/tc-admin/error');
        }
    },
    methods: {
      init(){
        if(this.$store.getters['auth/isAdmin']){
          this.menu.restaurant_id = this.$route.params ? this.$route.params.id : '';
          this.redirect = `/tc-admin/restaurante/${this.menu.restaurant_id}/administrar/cartas`;

        }
        else{
          this.menu.restaurant_id =  this.$store.getters['auth/restaurant'];
          this.redirect = '/tc-admin/cartas';
        }

        if(this.$route.params.menu && this.$route.params.menu != "crear"){
          this.menu.id =  this.$route.params.menu;
          this.getmenu();
        }
        else
          this.getForm();


      },
      async initPrice(){
        this.loading = {active: true, opacity: 0.95, text: 'Obteniendo Datos'};
        const url = `api/restaurants/settings?restaurant_id=${this.idRestaurant}`;
        try{
          await this.$api.get(url).then(res=>{
            this.coins = JSON.parse(res.data.data.settings.coins);
            var moneda;
            var exists;

            //Compruebo si existe un precio previo
            if (this.menu.price) {
              //Convierto en json el precio
              this.menu.price = JSON.parse(this.menu.price);
              //Copio el array de los precios
              var variation = this.clonar(this.menu.price);
              //comprobar si fue quitada alguna moneda
              for (let i = 0; i < variation.length; i++) {
                const prices = variation[i];
                for (let o = 0; o < prices.prices.length; o++) {
                  const price = prices.prices[o];
                    for (let a = 0; a < this.coins.length; a++) {
                      const coins = this.coins[a];
                      exists = false;
                      if (price.coin == coins.cod) {
                        exists = true;
                        break;
                      }
                  }
                  if (!exists) {
                      this.menu.price[i].prices.splice(o, 1);
                    }
                }
              }
              console.log(this.menu.price)


              //comprobar si fue añadida una nueva moneda
              for (let a = 0; a < variation.length; a++) {
                console.log(1);
                const vari = variation[a];
                //Recorro las monedas que el restaurante tiene
                for (let e = 0; e < this.coins.length; e++) {
                  console.log(2);
                  const coins = this.coins[e];
                  //Recorro los precios de la variacion
                  for (let i = 0; i < vari.prices.length; i++) {
                    console.log(3);
                    const prices = vari.prices[i];
                    exists = false;
                    moneda = coins.cod;
                    console.log(moneda)
                    //Comparo si existe una moneda en los precios actuales
                    if (prices.coin == coins.cod) {
                      console.log(4);
                      exists = true
                      break;
                    }
                  }
                  //Si la empresa tiene una nueva moneda, se agrega la moneda al plato.
                  if (!exists) {
                    console.log('entro a crear')
                      this.menu.price[a].prices.push({amount:0,coin:moneda});
                    }
                }
              }
            }else{
              this.menu.price = [this.newPrice()];
            }

            /* if (this.menu.price) {
              this.menu.price = JSON.parse(this.menu.price);
              var prices = this.clonar(this.menu.price);
              for (let i = 0; i < prices.length; i++) {
                let priceMenu = prices[i];
                exists = false;
                for (let inx = 0; inx < this.coins.length; inx++) {
                  const coinMain = this.coins[inx];
                  moneda = coinMain.cod
                  if (priceMenu.cod == coinMain.cod ) {
                    exists = true;
                    break;
                  }
                }
                if (!exists) {
                  this.menu.price[i].prices.push({amount:0,coin:moneda});
                }
              }
            }else{
              this.menu.price = [this.newPrice()];
            } */

          });
          this.loading.active = false;
        }
        catch(e){
          this.loading.active = false;
          this.snackbar = {
            text:  JSON.stringify(e),
            type: 'error',
            active: true
          }
        }
      },
      resetmenu(){
        this.menu = {
          description: '',
          name: '',
          price: '',
          visibility: '',
          publication_date:  new Date().toISOString().substr(0, 10),
          categories: [],
          lang: 'es',
          image: '',
          restaurant_id: '',
          allergens: [],
          id: '',
          image_delete: false
        };

        this.menu_lang ={
          description: '',
          name: '',
        };
        this.menu_category = {
          name:'',
          description: '',
          restaurant_id: '',
          lang: 'es'
        };
        this.traslate = {
          description: '',
          name: '',
          lang: '',
        };
        this.$refs.menu_form.reset();
      },
      async getForm(){
        this.loading = {active: true, opacity: 0.95, text: 'Obteniendo Datos'};
        try{
          const { data } = await this.$api.get(`api/menus/create?restaurant_id=${this.menu.restaurant_id}`);

          this.dishes = data.data.dishes;
          this.initPrice();
        }
        catch(error){
          this.load_sale= false;
          this.snackbar = {
            text: 'Lo sentimos, hubo un error al intentar obtener las Datos.',
            type: 'error',
            active:true,
          };

        }
      },
      async getmenu(){
        this.loading = {active: true, opacity: 0.95, text: 'Obteniendo Datos'};
        try{
          const { data } = await this.$api.get(`api/menus/${this.$route.params.menu}/edit?restaurant_id=${this.menu.restaurant_id}`);

          this.menu_lang.name = data.data.menu.name;
          this.menu_lang.description = data.data.menu.description;
          this.menu.description = this.menu_lang.description.es;
          this.menu.name = this.menu_lang.name.es;
          this.menu.visibility = data.data.menu.visibility;
          this.menu.dishes = data.data.menu.dishes.map(item => item.id);
          this.menu.image = data.data.menu.image.url,
          this.menu.publication_date = data.data.menu.publication_date,
          this.menu.price = data.data.menu.price,
          this.menu.show_price = data.data.menu.show_price,
          this.dishes = data.data.dishes;

          const preview = document.querySelector('#imagePreviewmenu');
          preview.style.backgroundImage = `url("${process.env.VUE_APP_IMG_URL_API}images/menu/${this.menu.image}")`;
          preview.style.backgroundSize = 'contain';
          preview.style.backgroundPosition = 'center center';
          this.initPrice();
        }
        catch(error){
          if(error.response.status == '500'){
            this.$router.push({ name: 'Error-admin', query: {code: '500'}});
          }
          this.load_sale= false;
          this.snackbar = {
            text: 'Lo sentimos, hubo un error al intentar obtener las datos del Menú.',
            type: 'error',
            active:true,
          };
        }
      },
      validarDashe(visibility){
        this.$refs.menu_form.validate().then(result =>{
          if(result){
            this.menu.visibility = visibility;
            this.savemenu();
          }
        })
      },
      validateLang(){
        this.$refs.menu_lang.validate().then(result =>{
          if(result){
            this.savemenu(true);
          }
        })
      },
      async savemenu(lang = false){
        const url = 'api/menus';
        const method = 'post';
        let menu;

        const datamenu = new FormData;
        datamenu.append('description', lang ? this.traslate.description : this.menu.description);
        datamenu.append('name', lang ? this.traslate.name : this.menu.name);
        datamenu.append('visibility', this.menu.visibility);
        datamenu.append('lang', lang ? this.traslate.lang : this.menu.lang);
        datamenu.append('restaurant_id', this.menu.restaurant_id);
        datamenu.append('image_delete', +this.menu.image_delete);
        datamenu.append('show_price', +this.menu.show_price);

        for (let i = 0; i < this.menu.price.length; i++) {
          const price = this.menu.price[i];
          datamenu.append('price[]', JSON.stringify(price));
        }


        datamenu.append('id', this.menu.id);
        for(var i = 0; i < this.menu.dishes.length; i++){
          datamenu.append('dishes[]', this.menu.dishes[i]);
        }

        if(lang) this.modal_menu = false;

        this.loading = {active: true, opacity: 0.75, text: 'Guardando Datos'};

        if(Object.keys(this.imagenMenu).length != 0 ){
          //Compruebo si tiene una imagen valida
          if (this.imagenMenu.hasImage()) {
            menu = this.imagenMenu.promisedBlob((blob) =>{
            },'image/jpeg',0.7);
          }
        }

        if (menu) {
          menu.then(res =>{
            datamenu.append('image', res, 'filename.jpeg')
          }).
          then(res  =>  {
            const send_menu = this.$api({
              url: url,
              method: method,
              data: datamenu,
            });
            send_menu.then(res => {
              this.$root.$snakbar({
                text: res.data.message,
                type: 'success',
                active: true
              });

              if(lang){
                this.getmenu();
              }
              else{
                this.$router.push(`${this.redirect}`);
              }
              this.loading.active = false;
            }).catch(e =>{
              if(e.response.status == "422"){
                this.$refs.menu_form.setErrors(e.response.data.data);
              }
              else{
                this.$root.$snakbar({
                  text: e.response.data.message,
                  type: 'error',
                  active: true
                });
              }
              this.loading.active = false;
            });
          })
        }else{
          try{
            const send_menu = await this.$api({
              url: url,
              method: method,
              data: datamenu,
            });
            this.$root.$snakbar({
              text: send_menu.data.message,
              type: 'success',
              active: true
            });
            if(lang){
              this.getmenu();
            }
            else{
              this.$router.push(`${this.redirect}`);
            }
            this.loading.active = false;

          }
          catch(e){
            if(e.response.status == "422"){
              this.$refs.menu_form.setErrors(e.response.data.data);
            }
            else{
              this.$root.$snakbar({
                text: e.response.data.message,
                type: 'error',
                active: true
              });

            }
            this.loading.active = false;
          }
        }
      },
      renderImage(file){
        const image = file.target.files[0] || file.dataTransfer.files
        if(image.length === 0) return;

        this.menu.image = image;
        this.menu.image_delete = false;

        const preview = document.querySelector('#imagePreviewmenu');

        let reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onload = () => {
          if (image.type.match('image/png') || image.type.match('image/jpeg') || image.type.match('image/gif')) {
            preview.style.backgroundImage = `url("${ reader.result }")`;
            preview.style.backgroundSize = 'contain';
            preview.style.backgroundPosition = 'center center';
          }
        };
      },
      deleteImage(){
        this.menu.image = '';
        this.menu.image_delete = true;
        this.cargaInicialImagen = false;
      },
      menuLang(lang){
        this.traslate.name = this.menu_lang.name[lang] ? this.menu_lang.name[lang] : this.menu_lang.name.es;
        this.traslate.description = this.menu_lang.description[lang] ? this.menu_lang.description[lang] : this.menu_lang.description.es;
        this.traslate.lang = lang;
        this.modal_menu = true;
      },
      cambiarVisibilidad(e){
        //console.log(e);
         if (e === true) {
          e = 1;
        }else{
          e=0
        }
        this.$refs.menu_form.validate().then(result =>{
          if(result){
            this.menu.visibility = e;
            this.savemenu();
          }
        })
      },
      cambiarShowPrice(e){
        if (e === true) {
          e = 1;
        }else{
          e=0
        }
      },
      symbolCoin(val){
        for (let i = 0; i < this.coins.length; i++) {
          if (val == this.coins[i].cod) {
            return this.coins[i].symbol;
          }
        }
      },
      labelAmount(val){
        for (let i = 0; i < this.coins.length; i++) {
          if (val == this.coins[i].cod) {
            return 'Monto en: '+ this.coins[i].coin;
          }
        }

      },
      validatePric(){
        this.$refs.prices_menu.validate().then(result =>{
          if(result){
            this.modal_prices = false
          }
        });
      },
      restorePrices(){
        this.menu.price = [this.newPrice()];
        this.modal_prices = false;
      },
      newPrice(){
        let price = {name:"",prices:[]};
          for (let i = 0; i < this.coins.length; i++) {
            const coin = this.coins[i];
            let priceArray = {coin:coin.cod,amount:0};
            price.prices.push(priceArray);
          }
        return price;

      },
      formatNumber(val, country){
        this.$numeral.locale(country)
        return this.$numeral(val).format('0,0[.]00 $')
      },
      addPrice(){
        this.menu.price.push(this.newPrice())
      },
      removePrice(key) {
        this.menu.price.splice(key, 1);
      },
      clonar(obj) {
        if (obj === null || typeof obj !== "object") {
          return obj;
        }
        var temp = obj.constructor();
        for (var key in obj) {
          temp[key] = this.clonar(obj[key]);
        }
        return temp;
      }
    },
    computed:{
      visibilidadNombre(){
        const v = this.menu.visibility;
        if (v) {
          return 'Visible';
        }else{
          return 'No visible';
        }
      },
      showPriceNombre(){
        const v = this.menu.show_price;
        if (v) {
          return 'Mostrar precio';
        }else{
          return 'No mostrar precio';
        }

      },
      date(){
        const f = new Date();
        return f.toDateString();
      },
      lang(){
        let traslate = '';
        switch(this.traslate.lang){
          case 'en':
            traslate = 'Inglés';
          break;
          case 'fr':
            traslate = 'Francés';
          break;
          case 'zh':
            traslate = 'Chino';
          break;
          default:
            traslate = '';
        }

        return traslate;
      },
      coinSymbol() {
        return coinSymbol
      },
      coinCountry() {
        return coinCountry
      },
      idRestaurant(){
        if(this.$store.getters['auth/isAdmin']){
          return this.$route.params ? this.$route.params.id : '';

        }
        else{
          return this.$store.getters['auth/restaurant'];
        }
      },
    },
    filters:{
      visible(val){
        return val == 1 ? 'Pública' : 'Borrador';
      }
    }
  }
</script>
<style scope>
  .v-data-table__expand-icon{
    color: #00cae3 !important;
    font-size: 30px !important;
  }

  .sidebar_right{
    background: #fbfcfd !important;
  }

  .error-editor .ql-toolbar.ql-snow, .error-editor .ql-container.ql-snow{
    border: 1px solid #ff5252 !important;
  }
  .quill-editor,.editor{
  height: 100px !important
}
.editor{
  margin-bottom: 60px
}


</style>
